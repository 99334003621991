import {
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Staff, TeamMember } from "./types";
import type { VenuePayloadAction } from "app/types";

type venueId = number;

export interface StaffState {
    staff: Record<venueId, EntityState<Staff, number>>;
    team: Record<venueId, EntityState<TeamMember, number>>;
}

const staffAdapter = createEntityAdapter<Staff>();
const teamAdapter = createEntityAdapter<TeamMember>();

const initialState: StaffState = {
    staff: {},
    team: {},
};

export const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        setStaff: (state: StaffState, action: VenuePayloadAction<Staff[]>) => {
            state.staff[action.payload.venueId] = staffAdapter.setAll(
                state.staff[action.payload.venueId] ||
                    staffAdapter.getInitialState(),
                action.payload.data
            );
        },
        setTeam: (
            state: StaffState,
            action: VenuePayloadAction<TeamMember[]>
        ) => {
            state.team[action.payload.venueId] = teamAdapter.setAll(
                state.team[action.payload.venueId] ||
                    teamAdapter.getInitialState(),
                action.payload.data
            );
        },
        resetState: (state, action: PayloadAction) => {
            return initialState;
        },
    },
});

export const { setStaff, setTeam } = staffSlice.actions;

const staffSelectors = staffAdapter.getSelectors();
const teamSelectors = teamAdapter.getSelectors();

export const selectStaff = (state: RootState, venueId?: number) => {
    let staff = staffAdapter.getInitialState();
    if (venueId && state.staff.staff[venueId]) {
        staff = state.staff.staff[venueId];
    }
    return staffSelectors.selectAll(staff);
};

export const selectStaffWithSignoff = (state: RootState, venueId?: number) => {
    return selectStaff(state, venueId).filter(
        (staff) => staff.app_sign_off === 1
    );
};

export const selectTeam = (state: RootState, venueId?: number) => {
    let team = teamAdapter.getInitialState();
    if (venueId && state.staff.team[venueId]) {
        team = state.staff.team[venueId];
    }
    return teamSelectors.selectAll(team);
};

export const selectHasSetData = (state: RootState, venueId: number) => {
    return selectStaff(state, venueId) !== undefined;
};

export const selectStaffWithEmail = (
    state: RootState,
    venueId: number,
    email: string
): Staff | undefined => {
    return selectStaff(state, venueId).filter(
        (staff) => staff.email === email
    )[0];
};

export default staffSlice.reducer;
