import { createSelector } from "@reduxjs/toolkit";
import { ULID } from "types";
import { Staff } from "features/staff/types";
import { selectStaffWithSignoff } from "features/staff/staffSlice";
import {
    selectActiveUser,
    setActiveUser,
    setHasBeenIdle,
    setShowUserSelect,
    selectActiveVenueId,
} from "app/appSlice";
import { appDispatch } from "app/util";
import { useAppSelector } from "app/hooks";

export interface StaffSelectorData {
    staff: Staff[];
    activeUserId?: number | ULID;
    activeUser?: Staff;
    selectUserId: (userId: number | ULID | undefined) => void;
    dismiss: () => void;
}

const dismiss = () => {
    appDispatch(setShowUserSelect(false));
};

const selectUserId = (userId: number | ULID | undefined) => {
    appDispatch(setActiveUser(userId));
    appDispatch(setHasBeenIdle(false));
    dismiss();
};

const selectStaffWithSignoffMemo = createSelector(
    [(state) => state, (state, venueId) => venueId],
    (state, venueId) => selectStaffWithSignoff(state, venueId)
);

export const useStaffSelector = (): StaffSelectorData => {
    const venueId = useAppSelector(selectActiveVenueId);
    const staff = useAppSelector((state) =>
        selectStaffWithSignoffMemo(state, venueId)
    );
    const activeUserId = useAppSelector(selectActiveUser);
    const activeUser =
        staff.filter((staffMember) => staffMember.id === activeUserId)[0] ||
        null;

    return {
        staff,
        activeUserId,
        activeUser,
        selectUserId,
        dismiss,
    };
};

export default useStaffSelector;
