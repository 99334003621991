import React, { useCallback } from "react";
import useStaffSelector from "./useStaffSelector";
import styles from "./StaffSelector.module.css";

export default function StaffSelector() {
    const { staff, activeUserId, selectUserId, dismiss } = useStaffSelector();

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLLIElement>) => {
            let userId;
            if (e && e.currentTarget.dataset.staffId) {
                userId = e.currentTarget.dataset.staffId;
            }
            selectUserId(userId);
        },
        [selectUserId]
    );

    let menuItems = [];
    menuItems.push(
        <li onClick={handleClick} key="staff_selector_null">
            ----
        </li>
    );
    for (let staffMember of staff) {
        const key = "staff_selector_" + staffMember.id;
        const className =
            activeUserId === staffMember.id ? styles.activeUser : "";
        menuItems.push(
            <li
                className={className}
                data-staff-id={staffMember.id}
                onClick={handleClick}
                key={key}
            >
                {staffMember.name}
            </li>
        );
    }

    return (
        <div
            className={styles.container}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    dismiss();
                }
            }}
        >
            <div className={styles.staffSelector}>
                <ul className={styles.staffList}>{menuItems}</ul>
            </div>
        </div>
    );
}
