import { createSelector } from "@reduxjs/toolkit";
import { Staff } from "features/staff/types";
import { selectStaff } from "features/staff/staffSlice";
import {
    selectActiveUser,
    setShowUserSelect,
    selectHasBeenIdle,
    selectActiveVenueId,
} from "app/appSlice";
import { useAppSelector } from "app/hooks";
import { appDispatch, appSelect } from "app/util";
import type { ULID } from "types";

export interface ActiveStaffData {
    staff: Staff[];
    activeUserId?: number | ULID;
    activeUser?: Staff;
    hasBeenIdle: boolean;
    selectUser: () => void;
}

const selectUser = () => {
    appDispatch(setShowUserSelect(true));
};

const selectStaffMemo = createSelector(
    [(state) => state, (state, venueId) => venueId],
    (state, venueId) => selectStaff(state, venueId)
);

export const useActiveStaff = (): ActiveStaffData => {
    const venueId = useAppSelector(selectActiveVenueId);
    const staff = useAppSelector((state) => selectStaffMemo(state, venueId));
    const activeUserId = useAppSelector(selectActiveUser);
    const activeUser =
        staff.filter((staffMember) => staffMember.id === activeUserId)[0] ||
        null;
    const hasBeenIdle = appSelect(selectHasBeenIdle);

    return {
        staff,
        activeUserId,
        activeUser,
        hasBeenIdle,
        selectUser,
    };
};

export default useActiveStaff;
