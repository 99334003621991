import React, { ChangeEvent } from "react";
import { ComponentProps } from "./types";
import classnames from "classnames";
import formStyles from "./Form.module.css";
import styles from "./IntegerEntry.module.css";

interface Props extends ComponentProps {}

export default function IntegerEntry(props: Props) {
    let error;
    if (props.error) {
        error = <p className={formStyles.error}>{props.error.message}</p>;
    }
    let classes = [styles.control];
    if (props.unit) classes.push(styles.unitControl);
    let unitLabel;
    switch (props.unit) {
        case "m":
            unitLabel = "Minutes";
    }
    const value = props.value ? Number(props.value) : "";
    return (
        <div className={styles.IntegerEntry}>
            <p className={formStyles.label}>{props.label}</p>
            <div className={classnames(...classes)}>
                <input
                    type="number"
                    inputMode="numeric"
                    min="0"
                    name={props.model}
                    value={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        props.onChange(props.model, e.target.value);
                    }}
                />
                {props.unit && <div className={styles.unit}>{unitLabel}</div>}
            </div>
            {error}
        </div>
    );
}
