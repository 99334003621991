import { SQLDatetime, UUID, base64, json, timestamp, ULID } from "types";
import { CategoryView } from "features/environment/types";
import { FormView } from "./form/types";

export type FrequencyKey =
    | "halfDaily"
    | "daily"
    | "weekly"
    | "4weekly"
    | "monthly"
    | "3monthly"
    | "6monthly"
    | "yearly";
export type AnniversaryKey =
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday"
    | "morning"
    | "morningAfternoon"
    | "afternoon"
    | "evening"
    | "janAprJulOct"
    | "febMayAugNov"
    | "marJunSepDec"
    | "week1"
    | "week2"
    | "week3"
    | "week4"
    | "janJul"
    | "febAug"
    | "marSep"
    | "aprOct"
    | "mayNov"
    | "junDec"
    | "jan"
    | "feb"
    | "mar"
    | "apr"
    | "may"
    | "jun"
    | "jul"
    | "aug"
    | "sep"
    | "oct"
    | "nov"
    | "dec";

export type Unit = "C" | "F" | "pH" | "m";

export interface Schedule {
    id: number;
    rruleset: string;
    frequency_key: FrequencyKey;
    anniversary_key: AnniversaryKey;
    task_id: number;
    archived: number;
    created_at: SQLDatetime;
    updated_at: SQLDatetime | null;
    deleted_at: SQLDatetime | null;
}

// TODO: update to make this fit new category structure
export interface Category {
    description: string;
    id: number;
    name: string;
    configuration: CategoryView;
    rank?: number;
}

export type TaskType = "event" | "frequent" | "schedule";
export type TemplateStatus = "active" | "inactive" | "archived";
export type AuditView = "listly" | "daily" | "weekly" | "monthly" | "yearly";
export type AuditEventView = "checked" | "temperature" | "received";
export type TaskStatus = "due" | "urgent" | "upToDate" | "frequent" | "event";
export type TaskSource = "manual" | "sensor";

export const TaskUrgencies = {
    URGENT: 1,
    DUE: 2,
    FREQUENT: 3,
    OTHER: 4,
} as const;

export type TaskUrgency = typeof TaskUrgencies[keyof typeof TaskUrgencies];

export interface SensorReading {
    average: number;
    timestamp: timestamp;
}

export interface TaskData {
    category_id: number;
    template_name: string;
    configuration: FormView;
    id: number;
    method: string;
    name: string;
    proven_count: number;
    recent_schedules: Schedule[];
    schedule: Schedule | null;
    source: TaskSource;
    performed?: number;
    lastDue?: boolean;
    nextDueTime?: number;
    overdueTime?: number;
    isPerformed?: boolean;
    isDue?: boolean;
    lastDueCompleted?: number;
    reading?: SensorReading;
    urgency?: TaskUrgency;
    order?: number;
}

export interface TaskPerformedData {
    lastDueCompleted: timestamp;
    performed: timestamp;
    taskId: number;
}

export type taskFormComponentType =
    | "TextEntry"
    | "TimeEntry"
    | "DateEntry"
    | "CheckListIten";

export interface DiaryEntry {
    uuid: UUID;
    taskId: number;
    information: json;
    venueId: number;
    userId?: number | ULID;
    performed: number;
    performedOn?: SQLDatetime;
    due: number | undefined;
}

export interface DiaryFile {
    diary_entry: UUID;
    venueId: number;
    taskId: number;
    filename: string;
    file: base64;
    mimeType: string;
    performed: number;
}

export interface Segment {
    id: number;
    name: string;
    tasks: TaskData[];
}
